import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, NgZone, AfterViewChecked } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { UserService } from '../../../security/services/user.service';
import {
    SessionInfo, CmsContainerType, CmsExerciseClientSpec, CmsCourse,
    CmsSubject, CmsVersioningInfo, CmsItemState, CmsUpdateType, CmsCourseAudience, CmsUnitTestRef,
    ExerciseSaveResult
} from '../../types/navigator.types';
import { ICMS2ExerciseSpec, isInlineInteraction, isInteraction, ICMS2ExerciseContext, isInteractionReference, ICMS2InlineInteractionSpec, ICMS2InteractionRef, SymbolSpec } from '../../types/cms2-metadata.types';
import { ISessionId, IErrorData } from '../../../algebrakit/types/algebrakit.type';
import { IDebugInformation, IAlgebraKIT } from '../../../algebrakit/types/algebrakit.type';
import { SessionService } from '../../../algebrakit/services/session.service';
import { ExerciseService } from '../../services/exercise.service';
import { SubjectService } from '../../services/subject.service';
import { CourseService } from '../../services/course.service';
import { VersioningService } from '../../services/versioning.service';
import { ComponentCanDeactivate } from '../../../app/services/component-can-deactivate';
import { TypeHelpers } from '../../util/type-helpers';
import { AudienceSpec, I18nLabel, NameIdPair, QuestionMode } from '../../types/cms-metadata.types';
import { WidgetType, ICMS2InteractionSpec } from '../../types/cms2-metadata.types';
import { PublishedExerciseClientSpec } from '../../types/published.types';
import { AppProfileService } from '../../../app/services/app-profile.service';
import { I18nLabelService } from '../../services/i18n-label.service';
import { MetadataService } from '../../../algebrakit/services/metadata.service';
import { getSymbolAsLatex, renderMixedLatexStringToHTML } from '../../util/render-utils';
import { SymbolEditorWrapper } from '../../components/symbol-editor-wrapper/symbol-editor-wrapper';
import { getExtendedToastOption } from '../../../app/options/options';
import { RandomizedAlgebraPreviewComponent } from '../../components/randomized-algebra-preview/randomized-algebra-preview.component';
import { CmsInteractionComponent } from '../../../algebrakit/components/cms-interaction/cms-interaction.component';
import { AkitShortcutComponent } from '../../components/akit-shortcuts/akit-shortcuts.component';
import { ContentVersionService } from '../../../app/services/content-version-service';
import { IValidationReport, ValidationReport, ValidationStatus } from '../../types/ValidationReport';
import { SpecCollapsableComponent } from '../../components/spec-collapsable/spec-collapsable.component';
import { AkitVersionsService } from '../../../algebrakit/services/akit-versions.service';
import { akitVersionMismatch } from '../../util/akit-version-utils';

declare let $: any;
declare let AlgebraKIT: IAlgebraKIT;
let subscriptions: Subscription[] = [];



export function addModeToInteraction(mode: string, inter: ICMS2InteractionSpec | ICMS2InlineInteractionSpec | ICMS2InteractionRef) {
    if (!inter) return;
    if (isInteractionReference(inter)) return;
    if (isInlineInteraction(inter)) {
        if (inter.interactions) {
            inter.interactions.forEach(ii => addModeToInteraction(mode, ii));
        }
    } else {
        let _inter = inter as ICMS2InteractionSpec;
        let marr: string[] = [];
        if (_inter.modes) {
            marr = typeof (_inter.modes) == 'string' ? _inter.modes.split(',') : inter.modes as string[];
        }
        if (!marr.find(_m => _m == mode)) marr.push(mode);
        _inter.modes = marr;
    }
}

@Component({
    templateUrl: './exercise-client-spec-editor.component.html',
    styleUrls: ['./exercise-client-spec-editor.component.css', '../../../../assets/shared.less']
})
export class ClientExerciseSpecEditor extends ComponentCanDeactivate implements OnInit, AfterViewChecked {
    @ViewChild('specEditor', { static: false }) exerciseEditor: ElementRef;
    @ViewChild(SymbolEditorWrapper, { static: false }) interpretationEditor: SymbolEditorWrapper;

    @ViewChild(RandomizedAlgebraPreviewComponent, { static: false }) randomizedPreview: RandomizedAlgebraPreviewComponent;
    @ViewChild(CmsInteractionComponent, { static: false }) staticPreview: CmsInteractionComponent;

    @ViewChild('interpretationsModal', { static: false }) interpretationsModal: ElementRef;

    @ViewChild('validationCollapsable', { read: SpecCollapsableComponent, static: false }) validationCollapsable: SpecCollapsableComponent;

    testNameControl = new FormControl();
    testSessions: CmsUnitTestRef[];
    isTestCaseCtrl = new FormControl();

    updateTypeControl = new FormControl();
    commitMessageControl = new FormControl('', [this.commitMessageValidator]);

    exercise: CmsExerciseClientSpec;
    oldExercise: CmsExerciseClientSpec;
    updatedExerciseSpec: any;

    exerciseReady: boolean = false;;
    viewsReady: boolean = false;

    interpretations: SymbolSpec[];
    initialInterpretations: SymbolSpec[];
    _awaitingInterpretations: boolean;
    previousModalVisible: boolean;
    hasUnconfirmedInterpretations: boolean;

    demoExercise: ICMS2ExerciseSpec;
    course: CmsCourse;
    subject: CmsSubject;

    issueCopyCourse: CmsCourse;
    issueCopySubject: CmsSubject;
    issuePrefix: string;
    issueCopyPanelVisible: boolean = false;

    errorMessage: string;

    form: FormGroup;
    audiences: CmsCourseAudience[];
    courseSpecificAudiences: AudienceSpec[];
    audienceLangMap: { [audienceId: string]: string[] };

    versionInfo: CmsVersioningInfo;
    lastPublishedVersion: PublishedExerciseClientSpec;
    lastPublishedRetrieved: boolean = false;

    isDefinitionValid: boolean = true;
    changeCounter: number = 0;
    runSessionId: ISessionId;   //session id of test run. Used to store session as unit-test
    testSessionId: string;
    testSpec: ICMS2ExerciseSpec;
    createSessionPending: boolean = false;
    sessionName: string;
    isTestCase: boolean = false;
    errorObject: IErrorData;

    widgetTheme: string = 'akit';

    refreshTimer: any;

    publishingWarning = false;
    confirmPublish = false;

    metadataValid = true;
    questionModes = [
        { id: QuestionMode.ONE_BY_ONE, name: 'One by one' },
        { id: QuestionMode.ALL_AT_ONCE, name: 'All at once' }
    ]

    // bool to show title information in general settings tab
    generalExtend = false;

    // options var to define tooltip options 
    ttOptions = {
        'placement': 'top',
        'show-delay': 500
    }

    //If true, exercise cannot be opened in the current version of the cms
    hasExerciseReferences: boolean

    scriptNames: string[];

    JSON = JSON;

    widgetsEventsSet: boolean;

    showVersionInfo = false;

    testSpecCycle = 0;

    labelSubscription: any;
    i18nLabels: I18nLabel[];

    availablePalettes: string[];

    //To prevent "are you sure...." popup box from appearing twice
    leaveMessageAlreadyGiven: boolean;

    contentVersion: string; //content version of the currently chosen API
    akitVersion: string; //akit-version of the currently chosen API

    readOnly: boolean;

    rerunSymbolChange: boolean;
    waitForSpecChange: Promise<void>;
    resolveSpecChanged: () => void;

    validationReport: ValidationReport;

    get audiencesForSpecEditor() {
        return this.audiences
            ? this.audiences.map(a => {
                return {
                    id: a.baseAudienceId,
                    name: a.name,
                    languages: this.audienceLangMap[a.baseAudienceId]
                };
            })
            : [];

    }

    get resolveAudiencesForSpecEditor() {
        return this.courseSpecificAudiences
            ? this.courseSpecificAudiences.map(a => {
                return {
                    id: a.audienceID,
                    name: a.name || a.audienceID,
                    languages: a.languages
                };
            })
            : [];

    }

    get interactionBlacklist() {
        if (!this.course || !this.course.options) {
            return null
        }
        return this.course.options.interactionBlacklist;
    }

    get allowSettingSubmitOnEnter() {
        return this.userService.canSetSubmitOnEnter(this.exercise.course)
    }

    get changed() {
        return this.changeCounter > 0;
    }
    onCloseUrl: string;

    get saveTitle() {
        if (this.exercise.state != 'DRAFT' && !!this.commitMessageValidator()) {
            return 'You must add a commit message to update an approved exercise.'
        }
        return `Save this exercise (${AkitShortcutComponent.getSaveShortcut()})`
    }

    get runTitle() {
        return `Run this exercise (${AkitShortcutComponent.getRunShortCut()})`
    }

    get allowExperimental() {
        return this.userService.getUser().canUseExperimentalWidgets();
    }

    get awaitingInterpretations() {
        if ($('.interpretations-modal:visible').first().length > 0) {
            this.previousModalVisible = true;
            return true;
        }
        else if (this.previousModalVisible) {
            this._awaitingInterpretations = false;
            this.previousModalVisible = false;
        }
        return this._awaitingInterpretations;
    }

    get akitVersionMismatch() {
        return akitVersionMismatch(this.course, this.akitVersion);
    }

    get courseAkitVersion() {
        if (!this.course || !this.course.options) {
            return null;
        }
        return this.course.options.akitVersion;
    }

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private location: Location,
        private exerciseService: ExerciseService,
        private courseService: CourseService,
        private subjectService: SubjectService,
        private versioningService: VersioningService,
        private sessionService: SessionService,
        private userService: UserService,
        private appProfileService: AppProfileService,
        private i18nLabelService: I18nLabelService,
        private changeDetector: ChangeDetectorRef,
        private metadataService: MetadataService,
        private contentVersionService: ContentVersionService,
        private akitVersionsService: AkitVersionsService,
        private zone: NgZone
    ) {
        super();

        this.form = this.formBuilder.group({
            commitMessage: this.commitMessageControl,
            updateType: this.updateTypeControl,
        })
    }

    getCourseId() {
        if (this.course) {
            return this.course.id;
        }
        if (this.subject && this.subject.course) {
            return this.subject.course.id
        }
        return null;
    }

    showErrorMessage(err: any, fallBackMessage: string) {
        let message = err.message || err.message || fallBackMessage;
        this.toastr.error(message, 'Error');
    }

    commitMessageValidator() {
        return (control: AbstractControl): { [key: string]: any } => {
            return this.exercise.state == CmsItemState.APPROVED
                && (!control.value || ("" + control.value).trim() == "")
                ? { 'emptyCommitMessage': { value: control.value } }
                : null;
        };
    }

    setTested() {
        this.versioningService.setTestedTrue(this.exercise.id)
            .then(() => {
                this.exercise.tested = true;
                this.oldExercise = TypeHelpers.clone(this._getFormValue());
                this.toastr.success("Exercise marked as tested", 'Success')
            }).catch((err) => {
                console.log(err);
                this.showErrorMessage(err, "Error occurred while marking the exercise as tested");
            });
    }

    approveButtonVisible() {
        // return this.canApprove() && this.exercise.state == 'DRAFT' && this.canDeactivate() && this.form.valid && this.isDefinitionValid && this.metadataValid;
        return this.exercise.state == 'DRAFT' && this.form.valid && this.isDefinitionValid && this.metadataValid && !this.changed && this.canApprove();
    }

    canApprove(): boolean {
        return this.userService.canApproveCourseExercises(this.course);
    }

    canPublish(): boolean {
        return this.userService.canPublishInCourse(this.exercise.course) && this.exercise.state == 'APPROVED';
    }

    canCompareTestsessions() {
        return this.userService.canCompareTestSessions();
    }

    canUpdateSession() {
        return this.userService.canUpdateTestSessions();
    }

    canSetIsTestCase() {
        return this.userService.canSetIsTestCase();
    }

    getIssueCopySubject() {
        return this.userService.getIssueCopySubject();
    }

    approve() {
        if (confirm("You are about to move this exercise from the DRAFT to the APPROVED state and enable versioning for this exercise. Are you sure?")) {
            this.versioningService.approve(this.exercise.id)
                .then(() => {
                    this.exercise.state = CmsItemState.APPROVED;
                    this.getVersioningData();
                    this.oldExercise = TypeHelpers.clone(this._getFormValue());
                    this.toastr.success("Exercise is now approved.", 'Success');
                })
                .catch((err: any) => {
                    console.log(err);
                    this.showErrorMessage(err, "Error occurred while approving the exercise");
                })
        }
    }

    getVersioningData() {
        if (this.exercise.state == CmsItemState.APPROVED) {
            this.versioningService.getLatestVersionInfo(this.exercise.id)
                .then((versionInfo: CmsVersioningInfo) => {
                    this.versionInfo = versionInfo
                });
        }
    }

    publish() {
        if (this.publishingWarning) {
            this.confirmPublish = true;
        }
        else {
            this.doPublish();
        }
    }

    cancelPublish() {
        this.confirmPublish = false;
    }

    doPublish() {
        this.exerciseService.publish(this.exercise, this.versionInfo, this.lastPublishedVersion)
            .then((pubEx) => {
                this.confirmPublish = false;
                if (!pubEx) {
                    //Aborted, do nothing.
                    return;
                }
                this.lastPublishedVersion = pubEx as PublishedExerciseClientSpec;
                this.getVersioningData();
            })
    }

    getLastPublish() {
        this.lastPublishedRetrieved = false;
        if (this.exercise.state == CmsItemState.DRAFT) {
            this.lastPublishedRetrieved = true;
            return Promise.resolve(null);
        }
        this.exerciseService.getPublishedSpecification(this.exercise.id).then((pubEx) => {
            this.lastPublishedVersion = pubEx as PublishedExerciseClientSpec;
            this.lastPublishedRetrieved = true;
        });
    }

    cancel() {
        if (this.changed) {
            if (confirm("You have unsaved changes in this exercise. Are you sure you want to close the editor?")) {
                this.leaveMessageAlreadyGiven = true;
                this.leave();
            }
        }
        else {
            this.leave();
        }
    }

    leave() {
        this.releaseLock();
        if (this.onCloseUrl == '_BACK') {
            this.location.back();
        }
        else if (this.onCloseUrl) {
            this.router.navigate([this.onCloseUrl]);
        }
        else {
            this.router.navigate(['subject', this.exercise.subject.id]);
        }
    }

    commit() {

        this.zone.run(() => {

            if (this.exercise.state == 'APPROVED') {
                let commitMsg: string = this.form.value.commitMessage;
                if (commitMsg == null || commitMsg.trim() == "") {
                    alert('You need to provide a commit message when saving approved exercises.');
                    return;
                }
            }

            this.exercise = this._getFormValue();

            // Serialize the object to a JSON string
            const jsonString = JSON.stringify(this.exercise);

            // Calculate the size in bytes. 
            // Note: This is a simplification, assuming each character is 1 byte. 
            // For UTF-16 encoding, you might consider each character as 2 bytes.
            const sizeInBytes = new Blob([jsonString]).size;

            // Convert bytes to kilobytes (KB)
            const sizeInKB = sizeInBytes / 1024;

            if (sizeInKB > 200) {
                // Display an alert or confirmation dialog
                this.toastr.error(`This exercise is too big. Make sure all images are stored as assets or author it in a different way or create multiple exercises.`, 'Exercise too big');
            }

            this.changeCounter = 0;
            this.exerciseService.storeExerciseClientSpec(this.exercise)
                .then((savedObj: ExerciseSaveResult) => {
                    if (savedObj.validationReport) {
                        this.validationReport = new ValidationReport(savedObj.validationReport);

                        if (this.validationReport.itemValidationStatus !== ValidationStatus.PASSED) {
                            this.testSpec = null;
                            this.toastr.warning("Exercise was stored but might not run without errors.", 'Success')
                        } else {
                            this.toastr.success("Exercise was stored successfully.", 'Success');
                        }

                        if (this.validationCollapsable) {
                            this.validationCollapsable.setCollapsed(this.validationReport.itemValidationStatus === ValidationStatus.PASSED);
                        }
                    } else {
                        delete this.validationReport;
                        this.testSpec = null;
                        this.toastr.warning("Exercise was stored but could not be validated.", 'Success');
                    }

                    this.exercise.validationReport = savedObj.validationReport;
                    this.oldExercise = TypeHelpers.clone(this._getFormValue());
                    //Hide preview if checks failed to prevent showing wrong preview

                    this.exercise.tested = false;
                    this.getVersioningData();
                    this.commitMessageControl.setValue("");
                })
                .catch(err => {
                    console.log(err);
                    this.toastr.error("Error occurred while storing the exercise", 'Error');
                });

        });
    }

    setSubject(id: string): void {
        this.subjectService.getSubject(id).toPromise()
            .then(subject => {
                this.subject = subject;
                this.exercise.subject = {
                    id: subject.id, name: subject.name, containerType: CmsContainerType.FOLDER
                }
            })
            .catch(err => {
                console.log(err);
                this.toastr.error("Error occurred while retrieving subject", 'Error');
            });
    }

    setIssueSubject(id: string) {
        this.subjectService.getSubject(id).toPromise()
            .then(subject => {
                this.issueCopySubject = subject;
            })
            .catch(err => {
                console.log(err);
                this.toastr.error("Error occurred while retrieving subject", 'Error');
            });
    }

    copyToIsseCourse() {
        let newName = this.issuePrefix + ' ' + this.exercise.name;
        let subjectName = this.issueCopySubject.rootFolder
            ? this.issueCopyCourse.name
            : this.issueCopySubject.name
        this.exerciseService.copyExerciseTo(this.exercise.id, this.issueCopySubject.id, newName)
            .then(() => {
                let baseUrl = $('base').attr('href');
                let msg = `Exercise was copied to <a style="text-decoration: underline;" target="_blank" href="${baseUrl}/subject/${this.issueCopySubject.id}"><strong>${subjectName}</strong></a> issue course successfully.`;
                this.toastr.success(msg, 'Success', getExtendedToastOption());
            })
            .catch(err => {
                console.log(err);
                this.toastr.error("Error occurred while copying the exercise", 'Error');
            });
    }

    async run(spec?: ICMS2ExerciseSpec) {
        this._awaitingInterpretations = true;
        // prevent author is creating multiple sessions. Especially for slow exercises, this could hammer the engine server.
        if (this.createSessionPending) {
            alert('A test session is already being created. Please wait until it is finished.');
            return;
        }
        this.createSessionPending = true;

        if (AlgebraKIT._api.errorViewerComponent) AlgebraKIT._api.errorViewerComponent.clearErrorMessages();
        let theme = this.course.options && this.course.options.theme
            ? this.course.options.theme
            : 'akit';
        await AlgebraKIT._api.changeTheme(theme);
        let newTestSpec: ICMS2ExerciseSpec;
        if (spec) {
            newTestSpec = spec;
            // newTestSpec.scriptRef = this.exercise.id;  // //Mslob: removed: scriptRef is meant for references to library exercises
            delete newTestSpec.scriptRef;
        }
        else {
            let ex = this._getFormValue();
            if (ex) {
                newTestSpec = ex.definition;
                newTestSpec.course = this.exercise.course.id;
                newTestSpec.audience = ex.audience;
                // newTestSpec.scriptRef = this.exercise.id; //Mslob: removed: scriptRef is meant for references to library exercises
                delete newTestSpec.scriptRef;
            }
        }

        let specChanged = JSON.stringify(newTestSpec) !== JSON.stringify(this.testSpec);

        this.testSpec = newTestSpec;

        if (!specChanged) {
            //If spec stays the same, angular change detection won't trigger and the exercise is not refreshed
            //Refresh it here manually
            if (this.randomizedPreview) {
                this.randomizedPreview.generate();
            }
            else if (this.staticPreview) {
                this.staticPreview.generate();
            }
        } else {
            // in else, to prevent generate is done again in cms-interaction component
            this.testSpecCycle++;
        }

        this.errorMessage = null;
        this.runSessionId = null;
        this.testSessionId = null;
        this.errorObject = null;
    }

    onInteractionError(error: IErrorData) {
        this.createSessionPending = false;
        this.exerciseEditor.nativeElement.setErrorData(error);
        if (!error.data) {
            let msg = error.msg;
            if (msg) msg = renderMixedLatexStringToHTML(msg);
            this.errorMessage = msg;
        }
    }

    onValidationReport(report: IValidationReport) {
        this.exercise.validationReport = report;
        this.validationReport = new ValidationReport(report);
        if (this.validationCollapsable) {
            this.validationCollapsable.setCollapsed(this.validationReport.itemValidationStatus === ValidationStatus.PASSED);
        }
    }

    onSessionReplay() {
        if (AlgebraKIT._api.errorViewerComponent) AlgebraKIT._api.errorViewerComponent.clearErrorMessages();
        this.testSpec = null;
    }

    onDebugInfo(debugInfo: IDebugInformation) {
        if (!debugInfo) return;
        let bucket = debugInfo.buckets.find(b => b.description == 'Interpretations');
        if (bucket) {
            this.interpretations = bucket.items.map(item => {
                let obj = JSON.parse(item.value);
                let latex = getSymbolAsLatex(item.key);
                return {
                    name: item.key,
                    type: obj.type,
                    latex: latex
                }
            });
            var modal = $('.interpretations-modal').first();
            modal.modal('show');

            let self = this;
            modal.on("hidden.bs.modal", function () {
                self.dismissInterpretations()
            });
            this.initialInterpretations = this.interpretations;
            this.hasUnconfirmedInterpretations = true;
        }
        else {
            this._awaitingInterpretations = false;
            this.hasUnconfirmedInterpretations = false;
        }
    }

    previewLoaded() {
        window.scrollTo(0, document.body.scrollHeight);
    }

    setRunSessionId(sessionId: ISessionId) {
        this.createSessionPending = false;
        this.runSessionId = sessionId;
    }

    showIssueCopyPanel(visible: boolean) {
        this.issueCopyPanelVisible = visible;
    }

    editExercise(courseId: string, id: string): void {
        var modal = $('.exercise-demo').first();
        if (modal) modal.modal('hide');

        this.router.navigate(['edit/exercise', courseId, id]);
    }

    setErrorMessage(message: string) {
        if (message) message = renderMixedLatexStringToHTML(message);
        this.errorMessage = message;
    }

    testHasError() {
        return this.errorMessage && this.errorMessage.length > 0;
    }

    setMetadataValid(valid: boolean) {
        this.metadataValid = valid;
    }

    storeSession(event: Event) {
        (event.target as HTMLButtonElement).disabled = true;
        if (!this.runSessionId) {
            console.log("Cannot store session: no session id available.");
            return;
        }
        console.log(this.runSessionId.sessionId + ' (appId=' + this.runSessionId.appId);
        this.sessionService
            .storeTestSession(this.runSessionId.sessionId, this.sessionName, this.exercise.id, this.isTestCase)
            .then((sessionInfo: CmsUnitTestRef) => {
                if (!this.exercise.testSessions) this.exercise.testSessions = [];
                this.setLocalTestSessionArray();
                this.testNameControl.setValue("");
                this.sessionName = null;
                this.toastr.success(`Test session '${sessionInfo.description}' was stored succesfully`, 'Success');
            });
    }

    async confirmInterpretation() {
        let symbols: SymbolSpec[] = [];
        let interpretations = await this.interpretationEditor.getSymbols();
        let interpretationsComplete = this.initialInterpretations.map(interpretation => interpretation.name).every(name => interpretations.map(interpretation => interpretation.name).includes(name));

        interpretations.forEach(inter => {
            if (!symbols.some(_s => _s.name == inter.name)) {
                symbols.push(inter);
            }
            symbols = [...symbols];
        });
        this.exerciseEditor.nativeElement.appendSymbols(symbols);
        this.interpretations = null;

        if (interpretationsComplete) {
            this.hasUnconfirmedInterpretations = false;

            if (this.rerunSymbolChange) {
                this.waitForSpecChange = new Promise((resolve) => {
                    this.resolveSpecChanged = resolve;
                });
                this.waitForSpecChange.then(() => this.run());
            }
        }
        this._awaitingInterpretations = false;
    }


    async dismissInterpretations() {
        this.createSessionPending = false;
    }

    setLocalTestSessionArray() {
        this.testSessions = [];
        this.sessionService.getTestSessionRefs(this.exercise.id).then((result) => {
            this.testSessions = result;
        })
    }

    isSimpleExercise(ex: ICMS2ExerciseSpec) {
        let interactionType = TypeHelpers.isSingleInteraction(ex);
        if (interactionType == 'ALGEBRA' || interactionType == 'MULTISTEP') {
            let inter = ex.elements[0].interactions[0];
            if (isInteraction(inter)) {
                if ((inter as ICMS2InteractionSpec).widgetType != WidgetType.CALCBOOK) {
                    return true;
                }
            }
        }
        return false;
    }

    isScriptedExercise(ex: ICMS2ExerciseSpec) {
        if (ex.script) return true;
        if (ex.scriptVariables && ex.scriptVariables.length > 0) return true;
        return false;
    }

    showSolution(ex: ICMS2ExerciseSpec) {
        const singleInteractionType = TypeHelpers.isSingleInteraction(ex);
        if (singleInteractionType === 'STATISTICS') {
            return false;
        }
        return true;
    }

    inspectSession(session: SessionInfo) {
        session.active = !session.active;
    }

    isEmptyTranslation(trans: { [key: string]: any }) {
        for (let lang in trans) {
            let obj = trans[lang];
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) return false;
            }
        }
        return true;
    }

    handleSymbolsChanged(symbols: SymbolSpec[]) {
        this.rerunSymbolChange = true;
    }

    ngOnInit() {
        this.readOnly = true;
        this.metadataService.getAudiences().then(audiences => {
            this.audienceLangMap = {};
            for (let audience of audiences) {
                this.audienceLangMap[audience.audienceID] = audience.languages;
            }
        })

        const akitVersionPromise = this.akitVersionsService.currentVersion.then(v => {
            this.akitVersion = v;
        })

        this.viewsReady = false;
        this.labelSubscription = this.i18nLabelService.labels$.subscribe(
            labels => {
                this.i18nLabels = labels;
                if (this.exerciseEditor && this.exerciseEditor.nativeElement) {
                    AlgebraKIT.waitUntilReady(this.exerciseEditor.nativeElement)
                        .then(() => {
                            this.exerciseEditor.nativeElement.updateI18nLabels(labels);
                        })
                }
            }
        )
        this.appProfileService.getSetting('publishingWarning').then((value) => {
            this.publishingWarning = value;
        });

        this.route.queryParams
            .subscribe((params: Params) => {
                this.onCloseUrl = params['onCloseUrl'];
            });

        this.route.data
            .subscribe((data: { exercise: CmsExerciseClientSpec, course: CmsCourse }) => {
                this.readOnly = true;
                //Show alert for version mismatch
                this.contentVersionService.showVersionAlert();
                this.contentVersionService.getContentVersion().then(v => {
                    this.contentVersion = v;
                });
                this.leaveMessageAlreadyGiven = false;
                this.exerciseReady = false;
                if (data.exercise) {
                    this.refreshTimer = this.exerciseService.scheduleRefresh(data.exercise.id);

                    this.exercise = data.exercise;

                    if (this.exercise.validationReport && this.exercise.validationReport.validationResults) {
                        this.validationReport = new ValidationReport(this.exercise.validationReport);
                    }

                    AlgebraKIT._api.allowSettingSubmitOnEnter = this.allowSettingSubmitOnEnter;

                    this.hasExerciseReferences = this.exercise.definition
                        && this.exercise.definition.elements
                        && this.exercise.definition.elements.some(elm =>
                            elm.exerciseReferences && elm.exerciseReferences.length > 0
                        );

                    this.getLastPublish();

                    if (data.exercise.definition.modes) {
                        let marr: string[] = typeof (data.exercise.definition.modes) == 'string'
                            ? data.exercise.definition.modes.split(',').map(m => m.trim())
                            : data.exercise.definition.modes;
                        let delegateModes = ['units_are_optional', 'units_explicit'];
                        delegateModes
                            .filter(mode => marr.some(_m => _m == mode))
                            .forEach(m => {
                                data.exercise.definition.elements
                                    .filter(elm => elm.interactions)
                                    .forEach(elm => {
                                        elm.interactions
                                            .forEach(inter => addModeToInteraction(m, inter));
                                    });
                                marr = marr.filter(_m => _m != m);
                            });
                        data.exercise.definition.modes = marr //Modes should never be a single string anymore. Upgraders will crash if this is the case for exercises >= v2.0
                    }

                    const coursePromise = this.courseService.getCourse(data.exercise.course.id).toPromise().then(course => {
                        this.course = course;
                        this.audiences = course.audiences;
                        this.widgetTheme = this.course.options && this.course.options.theme
                            ? this.course.options.theme
                            : 'akit';

                        //Check if user can assign resolved audiences
                        if (this.userService.canAssignResolvedAudiences(this.course)) {
                            this.metadataService.getAudiences().then(audiences => {
                                this.courseSpecificAudiences = audiences.filter(a => a.audienceType == 'course_specific');
                            })
                        }

                        AlgebraKIT._api.changeDefaultInteractionSettings(this.widgetTheme);

                        //Get resolved editors
                        //Add custom editors
                        this.metadataService.getEditorAssetResolveList(this.course.id).then((result => {
                            this.availablePalettes = result.map(r => r.formulaEditorId);
                        }))

                        let issueSubjectId = this.getIssueCopySubject();
                        if (issueSubjectId && issueSubjectId.length > 0) {
                            this.subjectService.getSubject(issueSubjectId)
                                .toPromise().then(issueSubject => {
                                    this.issueCopySubject = issueSubject
                                    let issueCourseId = issueSubject.course.id;
                                    if (issueCourseId && issueCourseId !== this.course.id) {
                                        this.courseService.getCourse(issueCourseId).toPromise().then(issueCourse => {
                                            this.issueCopyCourse = issueCourse;
                                        });
                                    }
                                });
                        }

                        Promise.all([akitVersionPromise, coursePromise]).then(() => {
                            this.readOnly = this.akitVersionMismatch;
                        });

                        this.getVersioningData();
                        this.setLocalTestSessionArray();
                    });

                    this.subjectService.getSubject(this.exercise.subject.id)
                        .toPromise().then(subject => this.subject = subject);

                    this.changeCounter = 0;
                    this.exerciseReady = true;

                }
            });

        this.testNameControl.valueChanges.subscribe(value => {
            this.sessionName = value;
        });

        this.isTestCaseCtrl.valueChanges.subscribe(value => {
            this.isTestCase = value;
        })

        // show extended settings if they are present
        if (this.exercise) {
            this.generalExtend = (this.exercise.comment != null && this.exercise.comment.length > 0)
                || (this.exercise.description != null && this.exercise.description.length > 0)
                || (this.exercise.title != null && this.exercise.title.length > 0)
                || (this.exercise.definition.modes != null && this.exercise.definition.modes.length > 0);
        }
    }


    ngAfterViewChecked() {
        if (this.widgetsEventsSet) {
            return;
        }
        else if (this.exerciseEditor) {
            this.widgetsEventsSet = true;
            this.zone.runOutsideAngular(async () => {
                await AlgebraKIT.waitUntilReady(this.exerciseEditor.nativeElement);

                this.updatedExerciseSpec = { ...this.exercise };
                this.updatedExerciseSpec.definition = await this.exerciseEditor.nativeElement.getSpec();
                this.isDefinitionValid = await this.exerciseEditor.nativeElement.getValid();
                this.exerciseEditor.nativeElement.removeWidgetListener('exerciseChanged', this.onExerciseSpecChanged);
                this.exerciseEditor.nativeElement.addWidgetListener('exerciseChanged', this.onExerciseSpecChanged);
                this.viewsReady = true;
                this.changeCounter = 0;
            });
        }
    }

    onExerciseSpecChanged = async (e: { spec: any, valid: boolean }) => {
        this.updatedExerciseSpec = e.spec;
        this.updatedExerciseSpec.definition = this.updatedExerciseSpec.definition;
        this.isDefinitionValid = e.valid;
        this.changeCounter++;
        this.changeDetector.detectChanges();
        if (this.resolveSpecChanged !== undefined) {
            this.resolveSpecChanged();
        }
    }

    canDeactivate(): boolean {
        return this.readOnly || this.leaveMessageAlreadyGiven || !this.changed;
    }

    compareVersionNumbers() {
        if (!this.versionInfo || !this.lastPublishedRetrieved) {
            return -1;
        }
        if (!this.lastPublishedVersion) {
            return 1;
        }
        let compareResult = TypeHelpers.compareVersionNumbers(
            this.versionInfo.majorVersion,
            this.versionInfo.minorVersion,
            this.lastPublishedVersion.majorVersion,
            this.lastPublishedVersion.minorVersion
        );
        return compareResult;
    }

    getPublishButtonTitle() {
        if (!this.canPublish()) {
            return 'Please save the exercise first';
        }
        if (this.compareVersionNumbers() === 0) {
            return 'This version is already published';
        }
        if (this.compareVersionNumbers() === -1) {
            return 'A newer version of this exercise has already been published';
        }
        return 'Publish this exercise';
    }

    releaseLock(): void {
        if (this.exercise) {
            this.exerciseService.releaseLock(this.exercise.id);
        }
    }

    ngOnDestroy() {
        for (let s of subscriptions) s.unsubscribe();
        this.exerciseService.descheduleRefresh(this.refreshTimer);
        if (this.exercise) {
            this.exerciseService.releaseLock(this.exercise.id);
        }
    }

    _getFormValue(): CmsExerciseClientSpec {
        let obj = this.form.value;

        let audience = this.updatedExerciseSpec && this.updatedExerciseSpec.definition
            ? this.updatedExerciseSpec.definition.audience
            : null;

        // before 10-01-2023 the scriptRef was incorrectly set to the exercise Id. Clear it.
        if (this.exercise.definition) {
            delete this.exercise.definition.scriptRef;
        }
        return {
            ...this.exercise,//default: original values
            ...obj,           //override with settings in form
            ...this.updatedExerciseSpec,
            state: this.exercise.state, //updatedExerciseSpec may have an old state (e.g. after approving)
            audience: audience,
            folderId: this.subject ? this.subject.id : this.exercise.subject.id,
            updateType: CmsUpdateType[this.updateTypeControl.value as CmsUpdateType] || CmsUpdateType.MINOR,
            commitMessage: obj.commitMessage || ""
        }
    }
}
